@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-black: 0 0 0;
    --color-yellow: 255 199 39;
    --color-white: 255 255 255;
    --color-green: 52 156 66;
    --color-red: 252 42 61;
    --color-orange: 219 123 43;
    --color-gray-light: 241 241 241;
    --color-gray: 136 136 136;
    --color-dark-gray: 85 85 85;
    --color-indigo: 37 99 235;
  }

  /* Chrome, Edge, and Safari */
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--color-light-gray);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--color-gray);
    border-radius: 5px;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--color-dark-gray);
  }

  .blink_me {
    animation: blink-animation 1s steps(5, start) infinite;
  }

  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
}

.gm-style-iw .gm-style-iw-ch {
  padding-top: 5px !important;
}

.gm-style-iw .gm-style-iw-chr {
  justify-content: end;
}

.gm-style-iw button {
  width: max-content !important;
  height: max-content !important;
  position: absolute !important;
  margin: 5px !important;
}

.gm-style-iw button span {
  width: 20px !important;
  height: 20px !important;
  margin: 0px !important;
}
